import React, {useState, useRef} from "react";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import Newimages from "common/Newimage";
import './Homepage.css';
import YouTube from "react-youtube";
import Loginaskbox from "./HomeModal/Loginaskbox";
import Signupaskbox from "./HomeModal/Signupaskbox";
import { connect } from "react-redux";


const HomeContainer = (props) => {
  const [loginbox, setLoginbox] = useState(false);
  const [signupbox, setSignupbox] = useState(false);

  const closebox = () => setLoginbox(false);
  const closesignupbox = () => setSignupbox(false);

// Insight
const [activeTab, setActiveTab] = useState('user-tab1');
const [activeSection, setActiveSection] = useState('user');

const handleTabChange = (tabName) => {
  setActiveTab(tabName);
};

const handleSectionChange = (sectionName) => {
  setActiveSection(sectionName);
  setActiveTab(`${sectionName}-tab1`);
};

  // Video section
  const [mainVideo, setMainVideo] = useState({ videoId: 'g9qwu7dItG4', img: Newimages.vdimg1, text: "Know More about Saytum Technologies Pvt. Lmt.!" });
      const [sideVideo, setSideVideo] = useState({ videoId: 'C8OQwC96dkQ', img: Newimages.vdimg2, text: "Are You tired of Searching for a Courier Service Provider?" });
      const [isTransitioning, setIsTransitioning] = useState(false);
      const mainVideoRef = useRef(null);

      const swapVideos = () => {
          setIsTransitioning(true);
          setTimeout(() => {
              setMainVideo(prev => {
                  return { videoId: sideVideo.videoId, img: sideVideo.img, text: sideVideo.text };
              });
              setSideVideo(prev => {
                  return { videoId: mainVideo.videoId, img: mainVideo.img, text: mainVideo.text };
              });
              setTimeout(() => {
                  if (mainVideoRef.current) {
                      mainVideoRef.current.internalPlayer.playVideo();
                  }
                  setIsTransitioning(false);
              }, 300);
          }, 300);
      };

      const toggleMainVideoPlayPause = () => {
          if (mainVideoRef.current) {
              mainVideoRef.current.internalPlayer.getPlayerState() === 1 
                  ? mainVideoRef.current.internalPlayer.pauseVideo()
                  : mainVideoRef.current.internalPlayer.playVideo();
          }
      };

      const handleMainVideoStateChange = event => {
          if (event.data === 0) { // Video ended
              setIsTransitioning(false);
          }
      };

      const getVideoSize = () => {
        if (window.innerWidth >= 1024) {
            return { width: '710px', height: '397px' };
        } else if (window.innerWidth >= 768) {
            return { width: '480px', height: '270px' };
        } else {
            return { width: '320px', height: '180px' };
        }
      };

      // Mission
      const Flex = ({ x, y, z }) => {
        return (
            <div className="ndflex animate">
                <div className="header">
                    <img src={x} alt="" className="miss-img" />
                </div>
                <div className="miss-head">
                    {y}
                </div>
                <div className="miss-content">
                    {z}
                </div>
            </div>
        );
      }

  return (
    <div>
      <div className="wrap" id="home">
          <div className="content">
              <ul className="wlcm">
                  <li className="wlcm-saytum" id="wlcm-top">Logistics Made Personal:</li>
                  <li className="wlcm-saytum" id="wlcm-btm">Get Bidding, Get Moving!</li>
              </ul>
              <ul className="newlist">
                  <li className="nl-p1">Customize Your Journey</li>
                  <li className="nl-p2">Maximize Your Savings</li>
                  <li className="nl-p3">Accelerate Your Deliveries</li>
              </ul>
              <p className="extra">with Our Innovative Platform!</p>
              {!props.token && <div className="btns">
                  <button className="button-17" onClick={() => setLoginbox(true)}>Login</button>
                  {loginbox && <Loginaskbox closebox = {closebox}/>}
                  <button className="button-17" id="signup" onClick={() => setSignupbox(true)}>Sign up</button>
                  {signupbox && <Signupaskbox closesignupbox = {closesignupbox}/>}
              </div>}
          </div> 
          <div className="img-container">
              <img src={Newimages.mainImage} alt="Main Background" className="main-image" />
          </div>
      </div>
      {/* About Us Section */}
      <div className="abtus" id="aboutus">
      <div className="about-container">
          <div className="abtleft">
              <div className="imgs"><img src={Newimages.A} alt="About us" className="abt-img"/></div>
          </div>
          <div className="abtright">
              <h1>About Us</h1>
              <p>
              <b>Saytum is a digital platform that brings together multiple logistics service providers under one roof, enabling businesses to easily compare, select and book logistics services for their transportation and supply chain needs.</b>
              <p style={{paddingTop: 25}}>
              Welcome to Saytum platform, where we strive to simplify the transportation and logistics industry for businesses and individuals alike. Our platform brings together a vast network of reliable carriers and logistics providers, allowing users to easily compare and choose the best transportation options to fit their specific needs. With our user-friendly interface and advanced technology, we aim to streamline the end to end process, save time and money, and provide exceptional customer service.</p>
              </p>
          </div>
      </div>
      </div>

      {/* Mission and Vision Section */}
      <div className="misandvis" id="mission">
          <div className="mission-header">
              <h1>Mission and Vision</h1>
              <p>
                  Saytum is a digital platform that brings together multiple logistics service providers under one roof, enabling businesses to easily compare, select, and book logistics services for their transportation and supply chain needs.
              </p>
          </div>
          <div className="flex-container">
              <Flex x={Newimages.icon1} y="Cost and Time Efficiency" z="Our artificial intelligence technology is able to algorithmically search through all possible logistic service provider options based on fleet type, availability, location, and rating service to recommend the most ideal solution based on your needs, thus minimizing cost and delivery time." />
              <Flex x={Newimages.icon2} y="Excellence in Customer Experience" z="Saytum aims to deliver an excellent customer experience while you engage in our services. This is achieved by ensuring that customers have a seamless process from start to end, delivered through an intuitive in-app experience. We also strive to ensure that our technologies remain at the leading edge to continually deliver the best experience to you." />
              <Flex x={Newimages.icon3} y="Security" z="All logistic service providers that are onboard with Saytum must go through comprehensive background checks to ensure their legitimacy and past track record. This is done to protect your interests and ensure that your goods are always delivered without any complications or hiccups." />
          </div>
      </div>

      {/* Saytum Experience Section */}
      <div className="wrapper Insights">
    <div className="ins-head-container">
      <h2 className="ins-head">Saytum Experience!</h2>
    </div>
    <div className="insights-box">
    <div className="toggle-container">
        <ToggleSwitch activeSection={activeSection} handleSectionChange={handleSectionChange} />
      </div>
      {activeSection === 'user' && (
        <div className="insights-content">
          <div className="insights-left">
            <div className="ins-list">
              <ul className="ins-tabs">
                <li className={`tablinks ${activeTab === 'user-tab1' ? 'active' : ''}`} onMouseOver={() => handleTabChange('user-tab1')}>
                  <h1 className="ins-head1">List Your Consignment and Ensure Swift Delivery!</h1>
                  <p className="ins-desc1">Unlock the easiest way to ship your goods with our hassle-free service. Whether you're sending a small package or a large shipment, we've got you covered.</p>
                </li>
                <li className={`tablinks ${activeTab === 'user-tab2' ? 'active' : ''}`} onMouseOver={() => handleTabChange('user-tab2')}>
                  <h1 className="ins-head2">Find the Best Bid</h1>
                  <p className="ins-desc2">Simplify your shipping process with our innovative platform. Compare bids from multiple carriers and select the best price for your delivery needs.</p>
                </li>
                <li className={`tablinks ${activeTab === 'user-tab3' ? 'active' : ''}`} onMouseOver={() => handleTabChange('user-tab3')}>
                  <h1 className="ins-head3">Track Your Consignment with Ease</h1>
                  <p className="ins-desc3">Stay informed with live updates on your consignment's journey, including departure, transit, and arrival times.</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="insights-right">
            <div className="ins-images">
              <div className="tab-container">
                <div id="user-tab1" className={`tabcontent ${activeTab === 'user-tab1' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.usercons} alt="Consignment" />
                  </div>
                </div>
                <div id="user-tab2" className={`tabcontent ${activeTab === 'user-tab2' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.userauc} alt="Auction" />
                  </div>
                </div>
                <div id="user-tab3" className={`tabcontent ${activeTab === 'user-tab3' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.usetrack} alt="Track Order" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeSection === 'partner' && (
        <div className="insights-content">
          <div className="insights-left">
            <div className="ins-list">
              <ul className="ins-tabs">
                <li className={`tablinks ${activeTab === 'partner-tab1' ? 'active' : ''}`} onMouseOver={() => handleTabChange('partner-tab1')}>
                  <h1 className="ins-head1">Bid on Auction</h1>
                  <p className="ins-desc1">Choose from a range of bidding options, including fixed-price bids, volume-based bids, and performance-based bids, allowing you to tailor your proposal to meet our specific requirements.</p>
                </li>
                <li className={`tablinks ${activeTab === 'partner-tab2' ? 'active' : ''}`} onMouseOver={() => handleTabChange('partner-tab2')}>
                  <h1 className="ins-head2">Find Suitable Delivery</h1>
                  <p className="ins-desc2">Discover customized bidding opportunities that align with your courier service capabilities, ensuring that you compete for contracts that suit your strengths and expertise.</p>
                </li>
                <li className={`tablinks ${activeTab === 'partner-tab3' ? 'active' : ''}`} onMouseOver={() => handleTabChange('partner-tab3')}>
                  <h1 className="ins-head3">Optimize Your Delivery Solutions</h1>
                  <p className="ins-desc3">Seamlessly Integrate Your Workers and Fleet into Our Auction Platform and Participate in Multiple Bidings.</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="insights-right">
            <div className="ins-images">
              <div className="tab-container">
                <div id="partner-tab1" className={`tabcontent ${activeTab === 'partner-tab1' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.partauc} alt="Consignment" />
                  </div>
                </div>
                <div id="partner-tab2" className={`tabcontent ${activeTab === 'partner-tab2' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.partdel} alt="Auction" />
                  </div>
                </div>
                <div id="partner-tab3" className={`tabcontent ${activeTab === 'partner-tab3' ? 'active' : ''}`}>
                  <div className="image-container">
                    <img src={Newimages.partfleet} alt="Track Order" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
      </div>

      {/* Video Section */}
      <div className="vdo-container">
          <div className="vdo-row">
              <div className="vdo-column vdo-main">
                  <div className={`video-wrapper ${isTransitioning ? 'slide-out-left' : 'slide-in-right'}`} onClick={toggleMainVideoPlayPause}>
                      <YouTube
                          ref={mainVideoRef}
                          videoId={mainVideo.videoId}
                          className={`vdo-thumb1 ${isTransitioning ? 'fade-out' : 'fade-in'}`}
                          opts={{
                              ...getVideoSize(),
                              playerVars: {
                                  autoplay: 0,
                                  rel: 0,
                              },
                          }}
                          onStateChange={handleMainVideoStateChange}
                      />
                  </div>
                  <p className={`vdo-maintxt ${isTransitioning ? 'slide-out-left' : 'slide-in-right'}`}>{mainVideo.text}</p>
              </div>
              <div className="vdo-column vdo-side">
                  <div className={`video-wrapper vdo-smallrow ${isTransitioning ? 'slide-out-left-small' : 'slide-in-right-small'}`} onClick={swapVideos}>
                      <div className="vdo-thumb2-wrapper">
                          <img src={sideVideo.img} alt="Thumbnail" className="vdo-thumb2" />
                          <img src={Newimages.yticon} alt="YTlogo" className="play-button" onClick={swapVideos}/>
                      </div>
                      <p className="vdo-sidetxt">{sideVideo.text}</p>
                  </div>
              </div>
          </div>
      </div>

      {/* Meet our CEO */}
      {/* <div className="about-ceo">
          <div className="ceo-left">
              <h1>Meet our CEO</h1>
              <div className="ceo-content">
                <div className="ceo-text">
              <p className="ceo-name">
                  Dhiraj Sinha
                  <p className="ceo-details">With 16 years of logistics excellence, leading <b>Saytum Technologies Pvt Ltd</b> with a unique blend of industry insight and technical prowess.</p>
              </p>
              <p className="ceo-qoute">
              <i class="fa-solid fa-quote-left" style={{color: "#eeaf83"}} id="qoute-left"></i> Innovation drives logistics. The dedication to innovation and leadership drives mission to transform digital logistics solutions for esteemed clients. <i class="fa-solid fa-quote-right" style={{color: "#eeaf83"}} id="qoute-right"></i>
              </p>
              </div>
              <div className="image-wrapper">
                  <img src={Newimages.ceoimage1} alt="CEO" className="ceo-image" />
              </div>
              </div>
          </div>
      </div> */}

    </div>
  );
};

const mapsStateToProps = ({ authenticated }) => {
  return {
    token: authenticated.token,
  };
};
export default connect(mapsStateToProps,)(HomeContainer);